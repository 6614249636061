import HandshakeSVG from '../../../images/icons/handshake.svg';
import ScaleSVG from '../../../images/icons/scale.svg';
import TimeIsMoneySVG from '../../../images/icons/time-is-money.svg';

export const findYourBestMortgageValueProps = [
  {
    data: {
      catchphrase: 'Hassle-free comparison shopping',
      label: 'Hassle-free comparison shopping',
      text: 'Mortgage shopping is a pain. Our technology sorts through thousands of options to put your best offers right up front – including detailed closing costs. Forget about spam and sales pressure from lenders competing for your business; we never reveal your contact information to our marketplace.'
    },
    Image: {
      svg: TimeIsMoneySVG,
      imageName: 'time-is-money.svg',
      height: 68
    }
  },
  {
    data: {
      catchphrase: 'We cut the lender’s biggest expense to get you a better deal',
      label: 'We cut the lender’s biggest expense to get you a better deal',
      text: 'A huge portion of the cost of your mortgage goes to pay your loan officer’s commission. We cut that cost fee in half so you’ll find better rates and terms in our marketplace. Plus, we match you with a lender that fits your profile, so you save even more.'
    },
    Image: {
      svg: ScaleSVG,
      imageName: 'scale.svg',
      height: 68
    }
  },
  {
    data: {
      catchphrase: 'We’re (actually) on the same team',
      label: 'We’re (actually) on the same team',
      text: "When you go to a lender or a mortgage company, they make more money when you pay more in interest and fees. That’s not how we work. We're paid the same flat rate by every lender, across every loan type, for every customer. You don’t have to haggle to get your best deal, we’ll just show it to you, right up front."
    },
    Image: {
      svg: HandshakeSVG,
      imageName: 'handshake.svg',
      height: 68
    }
  }
];
